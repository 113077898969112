import agent from '../api/agent'

const incotermStore = {
  namespaced: true,
  state: {
    incotermList: [],
    incotermNameId: [],
    selectedIncoterm: {
    },
  },
  getters: {
  },
  mutations: {
    updateIncotermList(state, newValue) {
      state.incotermList = newValue
      state.incotermNameId = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.ic_code}`
        obj.value = nv.ic_id

        return obj
      })
    },

    updateSelectedIncoterm(state, newValue) {
      state.selectedIncoterm = newValue
    },
  },
  actions: {
    async loadIncotermList(context, query) {
      const result = await agent.Incoterm.all(query)
      context.commit('updateIncotermList', result.data)
    },

    async loadIncoterm(context, id) {
      const result = await agent.Incoterm.detail(id)
      context.commit('updateSelectedIncoterm', result.data)
    },

    async createIncoterm(context, incoterm) {
      await agent.Incoterm.add(incoterm)
    },
    async editIncoterm(context, incoterm) {
      await agent.Incoterm.update(incoterm)
    },

    async deleteIncoterm(context, id) {
      await agent.Incoterm.delete(id)
    },
  },
}

export default incotermStore
