import agent from '../api/agent'

const currencyStore = {
  namespaced: true,
  state: {
    currencyList: [],
    currencies: [],
    selectedCurrency: {
    },
  },
  getters: {
  },
  mutations: {
    updateCurrencyList(state, newValue) {
      state.currencyList = newValue
      state.currencies = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.ccy_mark}:${nv.ccy_code}`
        obj.value = nv.ccy_id
        obj.mark = nv.ccy_mark

        return obj
      })
    },

    updateSelectedCurrency(state, newValue) {
      state.selectedCurrency = newValue
    },
  },
  actions: {
    async loadCurrencyList(context, query) {
      const result = await agent.Currency.all(query)
      context.commit('updateCurrencyList', result.data)
    },

    async loadCurrency(context, id) {
      const result = await agent.Currency.detail(id)
      context.commit('updateSelectedCurrency', result.data)
    },

    async createCurrency(context, currency) {
      await agent.Currency.add(currency)
    },
    async editCurrency(context, currency) {
      await agent.Currency.update(currency)
    },

    async deleteCurrency(context, id) {
      await agent.Currency.delete(id)
    },
  },
}

export default currencyStore
