import agent from '../api/agent'

const balanceStore = {
  namespaced: true,
  state: {
    balanceSheet: [],
    f_balanceList: [],
  },

  getters: {
  },

  mutations: {

    updateBalanceSheet(state, newValue) {
      state.balanceSheet = newValue
    },

  },

  actions: {
    async loadBalanceSheet(context, Month) {
      console.log('month:', Month)
      try {
        const result = await agent.Balance.all({
          month: Month,
        })
        context.commit('updateBalanceSheet', result.data)
      } catch (error) {
        console.log(error)
      }
    },

  },
}

export default balanceStore
