<template>
  <component :is="resolveLayout">
    <router-view></router-view>
    <overlays :is-show="OverlayStatus"></overlays>
  </component>
</template>

<script>
import {
  computed,
} from '@vue/composition-api'
import {
  mapState,
} from 'vuex'
import {
  useRouter,
} from '@/utils'
import {
  isEmpty,
} from '@/core/utils/index'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import LayoutMobile from '@/layouts/Mobile.vue'
import Overlays from '@/components/Overlays.vue'
import {
  loadLanguageAsync,
} from '@/plugins/i18n'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
    LayoutMobile,
    Overlays,
  },
  computed: {
    ...mapState('app', ['OverlayStatus']),
  },
  created() {
    // this.loadSetting()
    this.changeLang()
  },
  methods: {
    // ...mapActions('app', ['loadSetting']),
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'mobile') return 'layout-mobile'

      return 'layout-content'
    })

    const changeLang = () => {
      let lang = localStorage.getItem('lang')
      console.log('changeLang from :', lang)
      if (isEmpty(lang)) {
        // eslint-disable-next-line prefer-destructuring
        lang = navigator.language.split('-')[0]
        console.log('lang', navigator.language)
      }

      loadLanguageAsync(lang)
    }

    return {
      resolveLayout,
      changeLang,
    }
  },
}
</script>
