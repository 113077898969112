import agent from '../api/agent'

const containerStore = {
  namespaced: true,
  state: {
    containerList: [],
    selectedContainer: {
    },

  },

  getters: {
  },

  mutations: {

    updateContainers(state, newValue) {
      state.containerList = newValue
    },

    clearContainers(state) {
      state.ContainerList = []
    },

    updateContainer(state, newValue) {
      state.selectedContainer = newValue
    },

    clearContainer(state) {
      state.selectedContainer = []
    },

  },

  actions: {

    async loadContainers(context, query) {
      context.commit('clearContainers')
      const result = await agent.Container.all(query)
      context.commit('updateContainers', result.data)
    },

    async loadContainer(context, id) {
      context.commit('clearContainer')
      const result = await agent.Container.detail(id)
      context.commit('updateContainer', result.data)
    },

    async createContainer(context, container) {
      await agent.Container.add(container)
    },

    async editContainer(context, container) {
      await agent.Container.update(container)
    },

    async deleteContainer(context, id) {
      await agent.Container.delete(id)
    },

  },
}

export default containerStore
