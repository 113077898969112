import agent from '../api/agent'

const contractStore = {
  namespaced: true,
  state: {

    contractings: [],
    contracteds: [],
    selectedContract: {
    },
    selectedPelletContract: '',
    selectedCrushedContract: '',
    contractIds: [],
    contractMaster: {
    },
    contractList: [],
    contractItemsList: [],
    selectedContractItem: {
    },
  },

  getters: {
  },

  mutations: {
    updateCrushedContracts(state, newValue) {
      state.crushedContracts = newValue

      // state.contractings = state.contractCrusheds.filter(c => c.contract_status === 2)
      // state.contracteds = state.contractCrusheds.filter(c => c.contract_status === 4)
    },

    updatePelletContracts(state, newValue) {
      state.pelletContracts = newValue

      // state.contractings = state.contractList.filter(c => c.contract_status === 2)
      // state.contracteds = state.contractList.filter(c => c.contract_status === 4)
    },
    updatePelletContract(state, newValue) {
      state.selectedPelletContract = newValue
    },

    updateCrushedContract(state, newValue) {
      state.selectedCrushedContract = newValue
    },

    updateContactMaster(state, newValue) {
      state.contractMaster = {
      }
      state.contractIds = newValue.map(v => {
        state.contractMaster[v.contract_id] = v.contract_name

        return v.contract_id
      })
    },

    clearCrushed(state) {
      state.crushedContracts = []
      state.contractings = []
      state.contracteds = []
      state.selectedCrushedContract = ''
    },
    clearPellet(state) {
      state.pelletContracts = []
      state.contractings = []
      state.contracteds = []
      state.selectedPelletContract = ''
    },

    updateContracts(state, newValue) {
      state.contractList = newValue
    },

    clearContracts(state) {
      state.contractList = []
    },

    updateContract(state, newValue) {
      state.selectedContract = newValue
    },

    clearContract(state) {
      state.selectedContract = []
    },

    updateContractItems(state, newValue) {
      state.contractItemsList = newValue
    },

    updateContractItem(state, newValue) {
      state.selectedContractItem = newValue
    },
  },

  actions: {
    async loadContracts(context, query) {
      context.commit('clearContracts')
      const result = await agent.Contract.all(query)
      context.commit('updateContracts', result.data)
    },

    async loadContract(context, id) {
      context.commit('clearContract')
      const result = await agent.Contract.detail(id)
      context.commit('updateContract', result.data)
    },

    async createContract(context, contract) {
      await agent.Contract.add(contract)
    },

    async editContract(context, contract) {
      await agent.Contract.update(contract)
    },

    async deleteContract(context, id) {
      await agent.Contract.delete(id)
    },

    // 粉砕済
    async loadCrushedContracts(context, query) {
      context.commit('clearCrushed')
      const result = await agent.ContractCrushed.all(query)
      context.commit('updateCrushedContracts', result.data)
    },

    async loadCrushedContract(context, id) {
      console.log('loadCrushedContract,id:', id)
      context.commit('clearCrushed')
      const result = await agent.ContractCrushed.detail(id)
      console.log('data:', result.data)
      context.commit('updateCrushedContract', result.data)
    },

    async createCrushedContract(context, contract) {
      await agent.ContractCrushed.add(contract)
    },

    async editCrushedContract(context, contract) {
      await agent.ContractCrushed.update(contract)
    },

    async deleteCrushedContract(context, id) {
      await agent.ContractCrushed.delete(id)
    },

    async loadPelletContracts(context, query) {
      context.commit('clearPellet')
      const result = await agent.ContractPellet.all(query)
      context.commit('updatePelletContracts', result.data)
    },

    async loadPelletContract(context, id) {
      context.commit('clearPellet')
      const result = await agent.ContractPellet.detail(id)
      context.commit('updatePelletContract', result.data)
    },

    async loadPelletContractIds(context) {
      const result = await agent.ContractPellet.getids()
      context.commit('updateContactMaster', result.data)
    },

    async createPelletContract(context, contract) {
      await agent.ContractPellet.add(contract)
    },

    async editPelletContract(context, contract) {
      await agent.ContractPellet.update(contract)
    },

    async deletePelletContract(context, id) {
      await agent.ContractPellet.delete(id)
    },

    // contract items
    async loadContractItems(context, query) {
      const result = await agent.Contract.allItems(query)
      context.commit('updateContractItems', result.data)
    },

    async loadContractItem(context, id) {
      const result = await agent.Contract.detailItem(id)
      context.commit('updateContractItem', result.data)
    },

    async createContractItem(context, contract) {
      await agent.Contract.addItem(contract)
    },

    async editContractItem(context, contract) {
      await agent.Contract.updateItem(contract)
    },

    async deleteContractItem(context, id) {
      await agent.Contract.deleteItem(id)
    },
  },
}

export default contractStore
