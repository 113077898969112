import Vue from 'vue'
import Vuex from 'vuex'
import appConfigStoreModule from '@/core/app-config/appConfigStoreModule'
import materialStore from './materialStore'
import productStore from './productStore'
import pelletStore from './pelletStore'

import countryStore from './countryStore'
import areaStore from './areaStore'
import customerStore from './customerStore'
import contractStore from './contractStore'
import containerStore from './containerStore'

import documentStore from './documentStore'

import quotationStore from './quotationStore'

import depositStore from './depositStore'
import accountReceivableStore from './accountreceivableStore'

import userStore from './userStore'
import roleStore from './roleStore'
import employeeStore from './employeeStore'
import dashboardStore from './dashboardStore'

import reserveStore from './reserveStore'
import reservedStore from './reservedStore'
import bidStore from './bidStore'

import mediaStore from './mediaStore'
import preorderStore from './preorderStore'
import auctionStore from './auctionStore'
import templateStore from './templateStore'
import currencyStore from './currencyStore'
import incotermStore from './incotermStore'
import destinationStore from './destinationStore'
import balanceStore from './balanceStore'
import app from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    materialStore,
    productStore,
    pelletStore,

    preorderStore,
    auctionStore,
    countryStore,
    areaStore,
    customerStore,

    quotationStore,
    userStore,
    roleStore,
    employeeStore,
    dashboardStore,
    contractStore,
    containerStore,

    documentStore,
    templateStore,
    currencyStore,
    incotermStore,
    destinationStore,
    appConfig: appConfigStoreModule,

    depositStore,
    accountReceivableStore,
    reserveStore,
    reservedStore,
    mediaStore,
    bidStore,
    balanceStore,
    app,
  },
})
