import agent from '../api/agent'

const preorderStore = {
  namespaced: true,
  state: {

    preorderList: [],
    preorderIds: [],
    selectedPreorder: {
    },
  },
  getters: {
  },
  mutations: {
    updatePreorders(state, newValue) {
      state.preorderList = newValue
      state.preorderIds = newValue.map(v => v.po_id)
    },

    clearPreorders(state) {
      state.preorderList = []
    },

    updateSelectedPreorder(state, newValue) {
      state.selectedPreorder = newValue
    },

    clearSelectedPreorder(state) {
      state.selectedPreorder = {
      }
    },
  },
  actions: {
    async loadPreorders(context, query) {
      const result = await agent.Preorder.all(query)

      context.commit('updatePreorders', result.data)
    },

    async loadPreorder(context, id) {
      const result = await agent.Preorder.detail(id)
      context.commit('updateSelectedPreorder', result.data)
    },

    async createPreorder(context, preorder) {
      await agent.Preorder.add(preorder)
    },
    async editPreorder(context, preorder) {
      const obj = {
        ...preorder,
      }
      await agent.Preorder.update(obj)
    },

    async deletePreorder(context, id) {
      await agent.Preorder.delete(id)
    },
  },
}

export default preorderStore
