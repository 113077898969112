import agent from '../api/agent'

const reservedStore = {
  namespaced: true,
  state: {
    reservedList: [],
    resvStatusList: [],
    approvedList: [],
    resvStatuses: [],
    resvStatusMaster: [],
    quotStatusMaster: [],

    selectedReserved: {
    },
    selectedQuotation: {
    },
    reservedProgress: {
    },

  },

  getters: {
  },
  mutations: {
    updateReservedList(state, newValue) {
      state.reservedList = newValue
    },

    updatedApprovedList(state, newValue) {
      state.approvedList = newValue
    },

    clearReservedList(state) {
      state.reservedList = []
    },

    updateSelectedReserved(state, newValue) {
      state.selectedReserved = newValue
    },

    updateSelectedQuotation(state, newValue) {
      state.selectedQuotation = newValue
      console.log('quotation:', newValue)
    },

    updateStatus(state, newValue) {
      state.resvStatusMaster = newValue.map(nv => {
        const obj = {
        }

        obj.text = `${nv.resv_stat}: ${nv.resv_stat_desc}`
        obj.value = nv.resv_stat

        return obj
      })
    },
    updateReservedProgress(state, newValue) {
      state.reservedProgress = newValue
    },
    clearReservedStatus(state) {
      state.resvStatusMaster = []
    },
  },
  actions: {
    async loadReserveds(context, query) {
      const result = await agent.Reserved.all(query)

      context.commit('updateReservedList', result.data)
    },

    async loadReserved(context, id) {
      const result = await agent.Reserved.detail(id)
      context.commit('updateSelectedReserved', result.data)
    },

    async editReserved(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.update(obj)
    },

    async decideReserved(context, reserve) {
      await agent.Reserved.decide(reserve)
    },

    async loadReservationStatus(context, query) {
      const result = await agent.Reserved.statuses(query)
      context.commit('updateStatus', result.data)
    },

    // 承認済一覧を取得
    async loadApprovedReservations(context, query) {
      const result = await agent.Reserved.approved(query)

      context.commit('updatedApprovedList', result.data)
    },

    // 見積書を作成する
    async createQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.createQuotation(obj)
    },

    async loadQuotation(context, id) {
      const result = await agent.Reserved.loadQuotation(id)
      context.commit('updateSelectedQuotation', result.data)
    },

    // 見積書を編集する
    async editQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.editQuotation(obj)
    },

    async deleteQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.deleteQuotation(obj)
    },

    async loadReservedProgress(context, id) {
      const result = await agent.Reserved.getProgress(id)
      context.commit('updateReservedProgress', result.data)
    },

    async finalizeQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.finalizeQuotation(obj)
    },

  },
}

export default reservedStore
