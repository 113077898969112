import agent from '../api/agent'

const templateStore = {
  namespaced: true,
  state: {
    templateList: [],
    templateNameId: [],
    selectedTemplate: {
    },
  },
  getters: {
  },
  mutations: {
    updateTemplates(state, newValue) {
      state.templateList = newValue
      state.templateNameId = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.tmpl_name}`
        obj.value = nv.tmpl_id

        return obj
      })
    },

    updateSelectedTemplate(state, newValue) {
      state.selectedTemplate = newValue
    },
  },
  actions: {
    async loadTemplates(context, query) {
      const result = await agent.Template.all(query)
      context.commit('updateTemplates', result.data)
    },

    async loadTemplate(context, id) {
      const result = await agent.Template.detail(id)
      context.commit('updateSelectedTemplate', result.data)
    },

    async createTemplate(context, template) {
      await agent.Template.add(template)
    },
    async editTemplate(context, template) {
      await agent.Template.update(template)
    },

    async deleteTemplate(context, id) {
      await agent.Template.delete(id)
    },
  },
}

export default templateStore
