import agent from '../api/agent'

const auctionStore = {
  namespaced: true,
  state: {

    auctions: [],
    selectedAuction: {
    },
  },
  getters: {
  },
  mutations: {
    updateAuctions(state, newValue) {
      state.auctions = newValue
    },

    updateSelectedAuction(state, newValue) {
      state.selectedAuction = newValue
    },
  },
  actions: {
    async loadAuctions(context, query) {
      const result = await agent.Auction.all(query)

      context.commit('updateAuctions', result.data)
    },

    async loadAuction(context, id) {
      const result = await agent.Auction.detail(id)
      context.commit('updateSelectedAuction', result.data)
    },

    async createAuction(context, auction) {
      const obj = {
        ...auction,
      }

      await agent.Auction.add(obj)
    },

    async editAuction(context, auction) {
      const obj = {
        ...auction,
      }
      await agent.Auction.update(obj)
    },
    async deleteAuction(context, id) {
      await agent.Auction.delete(id)
    },
  },
}

export default auctionStore
