import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function isMobile() {
  const flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
  )

  return flag
}
const routes = [
  {
    path: '/',
    redirect: to => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      const userRole = userData && userData.roles[0] ? userData.roles[0] : null
      if (!userRole) {
        return {
          name: 'auth-login',
          query: to.query,
        }
      }
      if (userRole === 'normal' || userRole === 'Staff') {
        return {
          name: 'mobile-dashboard',
        }
      }
      if (isMobile()) {
        return {
          name: 'mobile-dashboard',
        }
      }

      return {
        name: 'dashboard-list-new',
      }
    },
  },
  {
    path: '/media-list',
    name: 'media-list',
    component: () => import('@/views/setting/Media/MediaList.vue'),
    meta: {
      title: 'MediaList',
    },
  },
  {
    path: '/monthlytransferred-list/:month',
    name: 'monthlytransferred-list',
    component: () => import('@/views/stock/MonthlyTransferred/MonthlyTransferredList.vue'),
  },
  {
    path: '/perettogetsujiinfo/:id',
    name: 'perettogetsujiinfo',
    component: () => import('@/views/stock/MonthlyStocked/PerettoGetsujiInfo.vue'),
  },
  {
    path: '/dailystocked-list/:id/:month',
    name: 'dailystocked-list',
    component: () => import('@/views/stock/DailyStocked/DailyStockedList.vue'),
  },
  {
    path: '/proudctdailydetails/:id',
    name: 'proudctdailydetails',
    component: () => import('@/views/stock/DailyStocked/ProductDailyStocked.vue'),
  },
  {
    path: '/materialdailydetails/:id',
    name: 'materialdailydetails',
    component: () => import('@/views/stock/DailyStocked/MaterialDailyStocked.vue'),
  },
  {
    path: '/crusheddailydetails/:id',
    name: 'crusheddailydetails',
    component: () => import('@/views/stock/DailyStocked/CrushedDailyStocked.vue'),
  },
  {
    path: '/monthlystocked-edit/:month/:id',
    name: 'monthlystocked-edit',
    component: () => import('@/views/stock/MonthlyStocked/MonthlyStockedEdit_old.vue'),
  },
  {
    path: '/monthlystocked-list/:month',
    name: 'monthlystocked-list',
    component: () => import('@/views/stock/MonthlyStocked/MonthlyStockedList.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/dashboard-list',
    name: 'dashboard-list',
    component: () => import('@/views/dashboard/DashboardList/DashboardList.vue'),
  },
  {
    path: '/dashboard-list-new',
    name: 'dashboard-list-new',
    component: () => import('@/views/dashboard/DashboardList/DashboardListNew.vue'),
  },
  {
    path: '/dashboard-create',
    name: 'dashboard-create',
    component: () => import('@/views/dashboard/DashboardCreate/DashboardCreate.vue'),
  },
  {
    path: '/dashboard-edit/:id',
    name: 'dashboard-edit',
    component: () => import('@/views/dashboard/DashboardEdit/DashboardEdit.vue'),
  },

  {
    path: '/dashboardstockcrushed',
    name: 'dashboardstockcrushed',
    component: () => import('@/views/dashboardstockcrushed/DashboardStockCrushed.vue'),
  },

  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },

  {
    path: '/bidding-list',
    name: 'bidding-list',
    component: () => import('@/views/bidding/BiddingResult/BiddingList.vue'),
  },
  {
    path: '/bidding-determination/:id',
    name: 'bidding-determination',

    // component: () => import('@/views/bidding/BiddingConfirm/AuctionConfirmLayout.vue'),
    component: () => import('@/views/bidding/BiddingResult/BiddingDecision.vue'),
  },
  {
    path: '/biddingwon-list',
    name: 'biddingwon-list',
    component: () => import('@/views/bidding/BiddingWon/BiddingWonList.vue'),
  },

  {
    path: '/biddingwon-edit/:id',
    name: 'biddingwon-edit',
    component: () => import('@/views/bidding/BiddingWon/BiddingWonEdit.vue'),
  },
  {
    path: '/bidding-progress/:id',
    name: 'bidding-progress',
    component: () => import('@/views/bidding/Progress/BiddingProgress.vue'),
  },

  // 落札商品-見積書
  {
    path: '/bidwon-quotation/:id',
    name: 'bidwon-quotation',
    component: () => import('@/views/bidding/BiddingWon/BidwonQuotation.vue'),
  },
  {
    path: '/auction-result',
    name: 'auction-result',
    component: () => import('@/views/bidding/AuctionResult/AuctionResultTable.vue'),
  },

  // 契約商品 product-list
  {
    path: '/contract-product-list',
    name: 'contract-product-list',
    component: () => import('@/views/contract/Pellet/List.vue'),
  },

  // 見積
  {
    path: '/quotation-list',
    name: 'quotation-list',
    component: () => import('@/views/quotation/List.vue'),
  },

  {
    path: '/document-list',
    name: 'document-list',
    component: () => import('@/views/contract/Document/List.vue'),
  },

  {
    path: '/document-edit/:id',
    name: 'document-edit',
    component: () => import('@/views/contract/Document/DocumentEdit.vue'),
  },

  // コンテナー Container
  {
    path: '/container-list',
    name: 'container-list',
    component: () => import('@/views/contract/Container/List.vue'),
  },

  // コンテナー 新規
  {
    path: '/container-create/',
    name: 'container-create',
    component: () => import('@/views/contract/Container/Create.vue'),
  },

  // コンテナー Container
  {
    path: '/container-edit/:id',
    name: 'container-edit',
    component: () => import('@/views/contract/Container/Edit.vue'),
  },

  // 請求書
  {
    path: '/invoice-list',
    name: 'invoice-list',
    component: () => import('@/views/contract/Invoice/List.vue'),
  },
  { // 海外用
    path: '/invoice-preview/:id',
    name: 'invoice-preview',
    component: () => import('@/views/contract/Invoice/InvoicePreview.vue'),
  },
  { // 日本国内用
    path: '/invoice-preview-jpn/:id',
    name: 'invoice-preview-jpn',
    component: () => import('@/views/contract/Invoice/InvoicePreviewJpn.vue'),
  },
  {
    path: '/contract-list',
    name: 'contract-list',
    component: () => import('@/views/contract/Contract/contractList.vue'),
  },
  {
    path: '/contract-create',
    name: 'contract-create',

    component: () => import('@/views/contract/Contract/contractCreate.vue'),

    // component: () => import('@/views/contract/Contract/Create.vue'),
  },
  {
    path: '/contract-edit/:id',
    name: 'contract-edit',
    component: () => import('@/views/contract/Contract/contractEdit.vue'),
  },

  // {
  //   path: '/contract-create',
  //   name: 'contract-create',
  //   component: () => import('@/views/contract/Document/Create.vue'),
  // },

  // {
  //   path: '/contract-edit/:id',
  //   name: 'contract-edit',
  //   component: () => import('@/views/contract/Pellet/Edit.vue'),
  // },
  {
    path: '/contract-pellet-edit/:id',
    name: 'contract-pellet-edit',
    component: () => import('@/views/contract/Pellet/ContractEdit.vue'),
  },
  {
    path: '/contract-preview/:id',
    name: 'contract-preview',

    component: () => import('@/views/contract/Document/Preview.vue'),

    // component: () => import('@/views/contract/Document/Preview_contract.vue'),
  },

  // 売掛
  {
    path: '/ar-list',
    name: 'ar-list',
    component: () => import('@/views/sales/AccountReceivable/List.vue'),
  },

  // 入金 一覧
  {
    path: '/deposit-list',
    name: 'deposit-list',
    component: () => import('@/views/sales/Deposit/List.vue'),
  },

  {
    path: '/deposit-create',
    name: 'deposit-create',
    component: () => import('@/views/sales/Deposit/Create.vue'),
  },

  {
    path: '/deposit-edit/:id',
    name: 'deposit-edit',
    component: () => import('@/views/sales/Deposit/Edit.vue'),
  },

  {
    path: '/deposit-reconciliation/:id',
    name: 'deposit-reconciliation',
    component: () => import('@/views/sales/Deposit/DepositReconciliation.vue'),
  },

  // 貸借対照表
  {
    path: '/balancesheet-list/:month',
    name: 'balancesheet-list',
    component: () => import('@/views/sales/Balance/List.vue'),
  },

  // shipping
  {
    path: '/shipping-list',
    name: 'shipping-list',
    component: () => import('@/views/shipping/Shipping/ShippingList.vue'),
  },
  {
    path: '/shipping-create',
    name: 'shipping-create',
    component: () => import('@/views/shipping/Shipping/ShippingCreate.vue'),
  },
  {
    path: '/shipping-edit/:id',
    name: 'shipping-edit',
    component: () => import('@/views/shipping/Shipping/ShippingEdit.vue'),
  },

  // shipped
  {
    path: '/shipped-list',
    name: 'shipped-list',
    component: () => import('@/views/shipping/Shipped/ShippedList.vue'),
  },

  // country
  {
    path: '/country-list',
    name: 'country-list',
    component: () => import('@/views/setting/Country/CountryList.vue'),
  },
  {
    path: '/country-create',
    name: 'country-create',
    component: () => import('@/views/setting/Country/CountryCreate.vue'),
  },
  {
    path: '/country-edit/:id',
    name: 'country-edit',
    component: () => import('@/views/setting/Country/CountryEdit.vue'),
  },

  // area
  {
    path: '/area-list',
    name: 'area-list',
    component: () => import('@/views/setting/Area/AreaList.vue'),
  },
  {
    path: '/area-create',
    name: 'area-create',
    component: () => import('@/views/setting/Area/AreaCreate.vue'),
  },
  {
    path: '/area-edit/:id',
    name: 'area-edit',
    component: () => import('@/views/setting/Area/AreaEdit.vue'),
  },

  // customer
  {
    path: '/customer-list',
    name: 'customer-list',
    component: () => import('@/views/setting/Customer/CustomerList.vue'),
  },
  {
    path: '/customer-create',
    name: 'customer-create',
    component: () => import('@/views/setting/Customer/CustomerCreate.vue'),
  },
  {
    path: '/customer-edit/:id',
    name: 'customer-edit',
    component: () => import('@/views/setting/Customer/CustomerEdit.vue'),
  },

  // employee
  {
    path: '/employee-list',
    name: 'employee-list',
    component: () => import('@/views/setting/Employee/EmployeeList.vue'),
  },
  {
    path: '/employee-create',
    name: 'employee-create',
    component: () => import('@/views/setting/Employee/EmployeeCreate.vue'),
  },
  {
    path: '/employee-edit/:id',
    name: 'employee-edit',
    component: () => import('@/views/setting/Employee/EmployeeEdit.vue'),
  },

  // user
  {
    path: '/user-list',
    name: 'user-list',
    component: () => import('@/views/setting/User/UserList.vue'),
  },
  {
    path: '/user-create',
    name: 'user-create',
    component: () => import('@/views/setting/User/UserCreate.vue'),
  },
  {
    path: '/user-edit/:id',
    name: 'user-edit',
    component: () => import('@/views/setting/User/UserEdit.vue'),
  },
  {
    path: '/user-edit-pwd/:id',
    name: 'user-edit-pwd',
    component: () => import('@/views/setting/User/UserEditPwd.vue'),
  },

  // テンプレート
  {
    path: '/template-list',
    name: 'template-list',
    component: () => import('@/views/setting/Template/List.vue'),
  },

  {
    path: '/template-create',
    name: 'template-create',
    component: () => import('@/views/setting/Template/Create.vue'),
  },

  {
    path: '/template-edit/:id',
    name: 'template-edit',
    component: () => import('@/views/setting/Template/Edit.vue'),
  },

  // role
  {
    path: '/role-list',
    name: 'role-list',
    component: () => import('@/views/setting/Role/RoleList.vue'),
  },
  {
    path: '/role-create',
    name: 'role-create',
    component: () => import('@/views/setting/Role/RoleCreate.vue'),
  },
  {
    path: '/role-edit/:id',
    name: 'role-edit',
    component: () => import('@/views/setting/Role/RoleEdit.vue'),
  },

  // contract item
  {
    path: '/contractItem-list',
    name: 'contractItem-list',
    component: () => import('@/views/setting/contractItem/contractItemList.vue'),
  },
  {
    path: '/contractItem-create',
    name: 'contractItem-create',
    component: () => import('@/views/setting/contractItem/contractItemCreate.vue'),
  },
  {
    path: '/contractItem-edit/:id',
    name: 'contractItem-edit',
    component: () => import('@/views/setting/contractItem/contractItemEdit.vue'),
  },

  // master
  {
    path: '/master-list',
    name: 'master-list',
    component: () => import('@/views/setting/Master/MasterList.vue'),
  },

  // stock
  {
    path: '/stock-list',
    name: 'stock-list',
    component: () => import('@/views/stock/StockList.vue'),
  },

  // pellet
  {
    path: '/pellet-list',
    name: 'pellet-list',
    component: () => import('@/views/setting/pellet/PelletList.vue'),
  },

  {
    path: '/pellet-create',
    name: 'pellet-create',
    component: () => import('@/views/setting/pellet/PelletCreate.vue'),
  },
  {
    path: '/pellet-edit/:id',
    name: 'pellet-edit',
    component: () => import('@/views/setting/pellet/PelletEdit.vue'),
  },

  // preorder(予約商品)
  {
    path: '/preorder-list',
    name: 'preorder-list',
    component: () => import('@/views/item/PreOrder/PreOrderList.vue'),
  },
  {
    path: '/preorder-create',
    name: 'preorder-create',
    component: () => import('@/views/item/PreOrder/PreOrderCreate.vue'),
  },
  {
    path: '/preorder-edit/:id',
    name: 'preorder-edit',
    component: () => import('@/views/item/PreOrder/PreOrderEdit.vue'),
  },

  // auction(入札商品)
  {
    path: '/auction-list',
    name: 'auction-list',
    component: () => import('@/views/item/Auction/AuctionList.vue'),
  },
  {
    path: '/auction-create',
    name: 'auction-create',
    component: () => import('@/views/item/Auction/AuctionCreate.vue'),
  },
  {
    path: '/auction-edit/:id',
    name: 'auction-edit',
    component: () => import('@/views/item/Auction/AuctionEdit.vue'),
  },

  // reservation
  {
    path: '/reservation-list',
    name: 'reservation-list',
    component: () => import('@/views/reservation/ReservationList/ReservationList.vue'),
  },
  {
    path: '/reservation-create',
    name: 'reservation-create',
    component: () => import('@/views/reservation/ReservationList/ReservationCreate.vue'),
  },
  {
    path: '/reservation-edit/:id',
    name: 'reservation-edit',
    component: () => import('@/views/reservation/ReservationList/ReservationEdit.vue'),
  },
  {
    path: '/reserved-list',
    name: 'reserved-list',
    component: () => import('@/views/reservation/Reserved/ReservedList.vue'),
  },
  {
    path: '/reserved-edit/:id',
    name: 'reserved-edit',
    component: () => import('@/views/reservation/Reserved/ReservedEdit.vue'),
  },

  {
    path: '/reserved-progress/:id',
    name: 'reserved-progress',
    component: () => import('@/views/reservation/Progress/ReservedProgress.vue'),
  },

  // 予約実績 承認済 一覧
  {
    path: '/approved-list',
    name: 'approved-list',
    component: () => import('@/views/reservation/Approved/ApprovedList.vue'),
  },

  // 予約実績 承認済 仮契約
  {
    path: '/approved-edit/:id',
    name: 'approved-edit',
    component: () => import('@/views/reservation/Approved/ApprovedEdit.vue'),
  },

  // 予約商品-見積書
  {
    path: '/approved-quotation/:id',
    name: 'approved-quotation',
    component: () => import('@/views/reservation/Approved/ApprovedQuotation.vue'),
  },

  {
    path: '/reserved-contract',
    name: 'reserved-contract',
    component: () => import('@/views/reservation/ReservedContract/ReservedContractList.vue'),
  },
  {
    path: '/invoice-create',
    name: 'invoice-create',
    component: () => import('@/views/reservation/ReservedContract/InvoiceCreate.vue'),
  },
  {
    path: '/invoice-edit/:id',
    name: 'invoice-edit',
    component: () => import('@/views/reservation/ReservedContract/InvoiceEdit.vue'),
  },

  // {
  //   path: '/invoice-preview/:id',
  //   name: 'invoice-preview',
  //   component: () => import('@/views/reservation/ReservedContract/InvoicePreview.vue'),
  // },

  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/mobile-dashboard',
    name: 'mobile-dashboard',
    component: () => import('@/views/mobile/MobileDashboard.vue'),
    meta: {
      layout: 'mobile',
      title: 'Dashboard',
    },
  },
  {
    path: '/mobile-router/:num',
    name: 'mobile-router',
    component: () => import('@/views/mobile/route/MobileProductionRoute.vue'),
    meta: {
      layout: 'mobile',
      title: 'ルーター',
    },
  },
  {
    path: '/mobile-crushing',
    name: 'mobile-crushing',
    component: () => import('@/views/mobile/crushing/MobileCrushing.vue'),
    meta: {
      layout: 'mobile',
      title: '粉砕',
    },
  },
  {
    path: '/mobile-blender',
    name: 'mobile-blender',
    component: () => import('@/views/mobile/blender/MobileBlender.vue'),
    meta: {
      layout: 'mobile',
      title: 'ブレンダー',
    },
  },

  // 契約ー見積
  {
    path: '/quotation-preview/:id',
    name: 'quotation-preivew',
    component: () => import('@/views/quotation/QuotationPreview.vue'),
  },

  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Resolve route duplication
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
