import agent from '../api/agent'

const customerStore = {
  namespaced: true,
  state: {
    customers: [],
    allowedCustomers: [],
    customer: undefined,
    custStatuses: [],
    selectedCustomer: {
    },

    // f_customers: [],
    customerIds: [],
    customerAbbrs: [],
    customerMaster: [],

    customerNames: [],
    mobileList: [],

    ccaList: {
    },
  },

  getters: {
  },

  mutations: {
    updateCustomers(state, newValue) {
      state.customers = newValue
    },

    updateAllowedCustomers(state, newValue) {
      state.allowedCustomers = newValue
    },

    updateSelectedCustomer(state, newValue) {
      state.selectedCustomer = newValue
    },
    updateCustStatus(state, newValue) {
      state.custStatuses = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.cust_stat_desc}`
        obj.value = nv.cust_stat

        return obj
      })
    },

    updateCustomerMaster(state, newValue) {
      state.customerMaster = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.cust_abbr}`
        obj.value = nv.cust_id

        return obj
      })
      state.customerIds = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.cust_abbr}`
        obj.value = nv.cust_id

        return obj
      })
    },

    clearCustomerMaster(state) {
      state.customerMaster = []
    },

    updateMobileNums(state, newValue) {
      state.mobileList = newValue
    },
    updateSupplierMaster(state, newValue) {
      state.supplierMaster = {
      }
      state.supplierIds = newValue.map(v => {
        state.supplierMaster[v.customer_id] = v.customer_name

        return v.customer_id
      })
    },

    updateCustomerName(state, newValue) {
      state.customerNames = newValue.map(v => v.customer_name)
    },

    updateCcaList(state, newValue) {
      newValue.data.forEach(c => {
        const Obj = {
        }

        if (c.country && c.country[0] && c.country[0].country_name) {
          Obj.country_name = c.country[0].country_name
        } else {
          Obj.country_name = ''
        }

        // if (c.area[0] && c.area[0].area_name) {
        if (c.area && c.area[0] && c.area[0].area_name) {
          Obj.area_name = c.area[0].area_name
        } else {
          Obj.area_name = ''
        }

        state.ccaList[c.customer_id] = Obj
      })
    },
  },

  actions: {
    async loadCustomers(context, query) {
      try {
        const result = await agent.Customer.all(query)

        context.commit('updateCustomers', result.data)

        // context.commit('updateCcaList', result)
      } catch (error) {
        console.log(error)
      }
    },
    async loadAllowedCustomers(context, query) {
      const result = await agent.Customer.allowed(query)
      context.commit('updateAllowedCustomers', result.data)
    },

    async loadCustomer(context, id) {
      const result = await agent.Customer.detail(id)
      context.commit('updateSelectedCustomer', result.data)
    },

    async loadCustomerIds(context) {
      const result = await agent.Customer.getids()
      context.commit('updateCustomerMaster', result.data)
    },

    async loadSupplierIds(context) {
      try {
        const result = await agent.Customer.getsids()

        context.commit('updateSupplierMaster', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async loadCustomerNames(context) {
      try {
        const result = await agent.Customer.getids()
        context.commit('updateCustomerName', result.data)
      } catch (error) {
        console.log(error)
      }
    },

    async createCustomer(context, customer) {
      await agent.Customer.add(customer)
    },

    async editCustomer(context, customer) {
      await agent.Customer.update(customer)
    },

    async deleteCustomer(context, id) {
      await agent.Customer.delete(id)
      context.dispatch('loadCustomers')
    },

    async loadCustomerStatus(context, query) {
      const result = await agent.Customer.statuses(query)
      context.commit('updateCustStatus', result.data)
    },
  },
}

export default customerStore
