import agent from '../api/agent'

const quotationStore = {
  namespaced: true,
  state: {
    quotationList: [],
    quotStatusMaster: [],
    selectedQuotation: {
    },

  },

  getters: {
  },
  mutations: {
    updateQuotationList(state, newValue) {
      state.quotationList = newValue
    },

    updateSelectedQuotation(state, newValue) {
      state.selectedQuotation = newValue
    },

    updateQuotStatus(state, newValue) {
      state.quotStatusMaster = newValue.map(nv => {
        const obj = {
        }

        obj.text = `${nv.quot_stat}: ${nv.quot_stat_desc}`
        obj.value = nv.quot_stat

        return obj
      })
    },

    clearQuotationList(state) {
      state.quotationList = []
    },

  },
  actions: {
    async loadQuotations(context, query) {
      const result = await agent.Quotation.all(query)

      context.commit('updateQuotationList', result.data)
    },

    // 見積書を作成する
    async createQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.createQuotation(obj)
    },

    async loadQuotation(context, id) {
      const result = await agent.Quotation.detail(id)
      context.commit('updateSelectedQuotation', result.data)
    },

    // 見積書を編集する
    async editQuotation(context, reserve) {
      const obj = {
        ...reserve,
      }
      await agent.Reserved.editQuotation(obj)
    },

    async loadQuotationsByCustomer(context, query) {
      const result = await agent.Quotation.ByCustomer(query)

      context.commit('updateQuotationList', result.data)
    },

    async loadQuotationStatus(context, query) {
      const result = await agent.Quotation.statuses(query)
      context.commit('updateQuotStatus', result.data)
    },

    // 見積書を破棄する
    async doContract(context, quotations) {
      const obj = {
        ...quotations,
      }
      await agent.Quotation.doContract(obj)
    },
  },
}

export default quotationStore
