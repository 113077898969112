import agent from '../api/agent'

const pelletStore = {
  namespaced: true,
  state: {
    pellets: [],
    pellet: undefined,
    selectedPellet: undefined,
    pelletMaster: {
    },

    pelletNameEn: {
    },
    pelletIds: [],
    pelletNames: [],

    // f_pellets: [],
  },
  getters: {
  },
  mutations: {
    updatePellets(state, newValue) {
      state.pellets = newValue

      state.pelletNameEn = {
      }
      state.pelletIds = newValue.map(v => {
        state.pelletNameEn[v.pellet_id] = v.pellet_name_eng

        return v.pellet_id
      })
    },
    updateSelectedPellet(state, newValue) {
      state.selectedPellet = newValue
    },

    updatePelletIds(state, newValue) {
      state.pelletIds = newValue.map(v => v.pellet_id)
    },

    clearPelletIds(state) {
      state.pelletIds = []
    },

    updatePelletNames(state, newValue) {
      state.pelletNames = newValue.map(v => v.pellet_name)
    },

    clearPelletNames(state) {
      state.pelletNames = []
    },

  },
  actions: {
    async loadPellets(context, query) {
      try {
        const result = await agent.Pellet.all(query)
        context.commit('updatePellets', result.data)
      } catch (error) {
        console.log(error)
      }
    },
    async loadPellet(context, id) {
      const result = await agent.Pellet.detail(id)
      context.commit('updateSelectedPellet', result.data)
    },

    async loadPelletIds(context) {
      try {
        const result = await agent.Pellet.getids()
        context.commit('updatePelletIds', result.data)
      } catch (error) {
        console.log(error)
      }
    },
    async loadPelletNames(context) {
      try {
        const result = await agent.Pellet.getids()
        context.commit('updatepelletNames', result.data)
      } catch (error) {
        console.log(error)
      }
    },
    async createPellet(context, pellet) {
      const obj = {
        ...pellet,
      }

      // if (obj.pellet_img !== '') {
      //   await agent.Pellet.uploadPhoto(obj.pellet_img).then(data => {
      //     // eslint-disable-next-line no-param-reassign
      //     obj.pellet_img = data.data
      //   })
      // } else {
      //   // eslint-disable-next-line no-param-reassign
      //   obj.pellet_img = ''
      // }

      await agent.Pellet.add(obj)
    },

    // async uploadPhoto(context, pellet) {
    //   await agent.Pellet.uploadPhoto(pellet)
    // },

    async editPellet(context, pellet) {
      const obj = {
        ...pellet,
      }

      // if (obj.pellet_img != null) {
      //   await agent.Pellet.uploadPhoto(obj.pellet_img).then(data => {
      //     obj.pellet_img = data.data
      //   })
      // } else {
      //   delete obj.pellet_img
      // }
      await agent.Pellet.update(obj)
    },
    async deletepellet(context, id) {
      await agent.Pellet.delete(id)
      context.dispatch('loadpellets')
    },
  },
}

export default pelletStore
