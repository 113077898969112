import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import Vuelidate from 'vuelidate'
import draggable from 'vuedraggable'
import vuelidateErrorExtractor from 'vuelidate-error-extractor'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import {
  i18n,
} from './plugins/i18n'

import FormGroup from '@/components/form/FormGroup.vue'

import './plugins/acl'

import formatDateTime from './utils/format'
import {
  deepCopy, isObjectEqual,
} from './utils/clone'
import {
  init,
} from '@/api/agent'

init(store, router)
Vue.config.productionTip = false
Vue.use(Vuelidate)
Vue.use(CKEditor)

Vue.component('draggable', draggable)

Vue.use(vuelidateErrorExtractor)
Vue.component('FormGroup', FormGroup)

Vue.prototype.$formatDt = formatDateTime
Vue.prototype.$deepCopy = deepCopy
Vue.prototype.$isObjectEqual = isObjectEqual

Vue.prototype.$getUniqueId = (len, prefix) => {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('')
  const UniqueId = []
  /* eslint no-bitwise: ["error", { "allow": ["|"] }] */
  for (let i = 0; i < len; i += 1) UniqueId[i] = chars[(Math.random() * chars.length) | 0]

  return `${prefix}-${UniqueId.join('')}`
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
