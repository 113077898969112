export function deepCopy(data) {
  if (typeof data !== 'object' || data === null) {
    throw new TypeError('the parameter is not object')
  }
  let newData
  if (Array.isArray(data)) {
    newData = []
    data.forEach(d => {
      if (typeof d !== 'object' || d === null) {
        newData.push(d)
      } else {
        newData.push(deepCopy(d))
      }
    })
  } else if (typeof data === 'object') {
    newData = {
    }
    const dataKeys = Object.keys(data)
    dataKeys.forEach(v => {
      const currentValue = data[v]
      if (typeof currentValue !== 'object' || currentValue === null) {
        // basic parameter deep copy
        newData[v] = currentValue
      } else if (Array.isArray(currentValue)) {
        // array deep copy
        newData[v] = deepCopy(currentValue)
      }
    })
  }

  return newData
}

export function isObjectEqual(obj1, obj2) {
  if (typeof obj1 === 'object' && typeof obj2 === 'object') {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true
    }
    const obj1Keys = Object.keys(obj1)
    const obj2Keys = Object.keys(obj2)
    if (obj1Keys.length === obj2Keys.length) {
      for (let i = 0; i < obj1Keys.length; i += 1) {
        const key = obj1Keys[i]
        if (!isObjectEqual(obj1[key], obj2[key])) {
          return false
        }
      }

      return true
    }
  } else if (obj1 === obj2) {
    return true
  } else if (obj1 && obj2) {
    if (obj1.toString() === obj2.toString()) {
      return true
    }
  }

  return false
}

export function deepClone(target) {
  let result
  if (typeof target === 'object') {
    if (Array.isArray(target)) {
      result = []
      for (let i = 0; i < target.length; i += 1) {
        result.push(deepClone(target[i]))
      }
    } else if (target === null) {
      result = null
    } else if (target.constructor === RegExp) {
      result = target
    } else {
      result = {
      }
      Object.keys(target).forEach(key => {
        result[key] = deepClone(target[key])

        return result[key]
      })
    }
  } else {
    result = target
  }

  return result
}
