import agent from '../api/agent'

const depositStore = {
  namespaced: true,
  state: {
    depositList: [],
    selectedDeposit: {
    },

  },

  getters: {
  },
  mutations: {
    updateDepositList(state, newValue) {
      state.depositList = newValue
    },

    updateSelectedDeposit(state, newValue) {
      state.selectedDeposit = newValue
      console.log('deposit:', state.selectedDeposit)
    },

    clearDepositList(state) {
      state.depositList = []
    },

  },
  actions: {
    async loadDeposits(context, query) {
      const result = await agent.Deposit.all(query)

      context.commit('updateDepositList', result.data)
    },

    async createDeposit(context, deposit) {
      await agent.Deposit.add(deposit)
    },

    async loadDeposit(context, id) {
      const result = await agent.Deposit.detail(id)
      context.commit('updateSelectedDeposit', result.data)
    },

    // 見積書を編集する
    async editDeposit(context, deposit) {
      const obj = {
        ...deposit,
      }
      await agent.Deposit.update(obj)
    },

  },
}

export default depositStore
