import agent from '../api/agent'

const bidStore = {
  namespaced: true,
  state: {
    biddings: [],
    bidStatusMaster: [],
    selectedBid: {
    },
    selectedQuotation: {
    },
    biddingProgress: {
    },

  },
  getters: {
  },
  mutations: {
    updateBids(state, newValue) {
      state.biddings = newValue
    },

    updateSelectedBid(state, newValue) {
      console.log('updatedSelectedBId')
      state.selectedBid = newValue

      console.log('updated selectedBid:', state.selectedBid)
    },

    updateStatus(state, newValue) {
      console.log('bidStatuses:', newValue)
      state.bidStatusMaster = newValue.map(nv => {
        const obj = {
        }

        obj.text = `${nv.bid_stat}: ${nv.bid_stat_desc}`
        obj.value = nv.bid_stat

        return obj
      })

      console.log('resvStatusMaster:', state.resvStatusMaster)
    },

    updateSelectedQuotation(state, newValue) {
      state.selectedQuotation = newValue
    },

    updateProgress(state, newValue) {
      state.biddingProgress = newValue
    },
  },
  actions: {
    async loadBiddings(context, query) {
      const result = await agent.Bid.all(query)

      context.commit('updateBids', result.data)
    },

    async loadWonBiddings(context, query) {
      const result = await agent.Bid.allwon(query)

      context.commit('updateBids', result.data)
    },

    async loadBid(context, id) {
      const result = await agent.Bid.detail(id)
      console.log('loadBid:', result.data)
      context.commit('updateSelectedBid', result.data)
    },

    async createBid(context, bid) {
      let uploaded = false
      const obj = {
        ...bid,
      }
      if (obj.bid_images.length !== 0) {
        await agent.Bid.uploadPhoto(obj.bid_images).then(data => {
          console.log('upload photo', data.data)
          obj.bid_images = data.data
          uploaded = true
        })
      } else {
        obj.bid_images = []
        uploaded = true
      }
      /* eslint-disable-next-line */
      while (!uploaded) { await new Promise(resolve => { setTimeout(() => { resolve() }, 1000) }) }
      await agent.Bid.add(obj)
    },

    async editBid(context, bid) {
      const obj = {
        ...bid,
      }
      console.log('bid_id:', bid.bid_id)
      await agent.Bid.update(obj)
    },

    async deleteBid(context, id) {
      await agent.Bid.delete(id)
    },

    async loadBiddingStatus(context, query) {
      const result = await agent.Bid.statuses(query)
      context.commit('updateStatus', result.data)
    },

    async bidtowin(context, bid) {
      await agent.Bid.win(bid)
    },

    async loadQuotation(context, id) {
      const result = await agent.Bid.loadQuotation(id)
      context.commit('updateSelectedQuotation', result.data)
    },

    // 見積書を作成する
    async createQuotation(context, biddings) {
      const obj = {
        ...biddings,
      }
      await agent.Bid.createQuotation(obj)
    },

    // 見積を決定する
    async finalizeQuotation(context, biddings) {
      const obj = {
        ...biddings,
      }
      await agent.Bid.finalizeQuotation(obj)
    },

    // 見積書を破棄する
    async deleteQuotation(context, biddings) {
      const obj = {
        ...biddings,
      }
      await agent.Bid.deleteQuotation(obj)
    },

    // 見積書を編集する
    async editQuotation(context, bid) {
      const obj = {
        ...bid,
      }
      await agent.Bid.editQuotation(obj)
    },

    async loadProgress(context, id) {
      const result = await agent.Bid.getProgress(id)
      context.commit('updateProgress', result.data)
    },
  },
}

export default bidStore
