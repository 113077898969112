function transFormat(value) {
  return (`0${value}`).slice(-2)
}

const formatDateTime = function formatDt(time, value) {
  const date = new Date(time)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const days = date.getDate()
  const hours = date.getHours()
  const mins = date.getMinutes()
  const seconds = date.getSeconds()

  return value.replace(/(yy)+/i, year).replace(/MM/, transFormat(month)).replace(/dd/i, transFormat(days)).replace(/hh/i, transFormat(hours))
    .replace(/mm/, transFormat(mins))
    .replace(/ss/, transFormat(seconds))
}

export default formatDateTime
