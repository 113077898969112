export default {
  Preorder: {
    Pellet: 'Pellet',
    PreorderID: 'PreorderID',
    PreorderCode: 'Product Code',
    Weight: 'Weight',
    PurchaseRange: 'PurchaseRange',
    PriceJPY: 'Price(JPY)',
    PriceUSD: 'Price(USD)',
    Period: 'Period',
  },
  Auction: {
    Pellet: 'Pellet',
    AuctionID: 'Auction ID',
    Weight: 'Weight',
    Code: 'Code',
    MinAmount: 'Min Amount',
    MinJPY: 'Min Amoun(JPY)',
    MinUSD: 'Min Amount(USD)',
    Period: 'Period',
  },

  Quotation: {
    Quotation: 'Quotation',
    QuotationCode: 'Quotation No.',
    QuotationDate: 'Quotation Date',
    Description: 'Please refer to the following quotation.',
    FinalAmount: 'Quote Amount',
    BidAmount: 'Bid Amount',
    BidUnit: 'Set',
    Subtotal: 'Subtotal',
    Discount: 'Discount',
    Total: 'Total',
    Code: 'No.',
    Date: 'Date',
    ItemInfo: 'Item Information',
    Qty: 'Quantity',
    Unit: 'Unit',
    Price: 'Price',
    Weight: 'Weight',
    Amount: 'Amount',
    Note: 'Note',
    Expiration: 'Expiration',
  },
  Invoice: {
    Code: 'Contract No.',
    Date: 'Contract Date',
  },
  GreenEarth: {
    CompanyName: 'Green Earth Co., Ltd.',
    Address: '1679-1, Nekomi, Bandou-shi, Ibaraki, Japan',
  },
}
