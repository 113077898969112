import agent from '../api/agent'

const destinationStore = {
  namespaced: true,
  state: {
    destinationList: [],
    destinationNameId: [],
    selectedDestination: {
    },
  },
  getters: {
  },
  mutations: {
    updateDestinationList(state, newValue) {
      state.destinationList = newValue
      state.destinationNameId = newValue.map(nv => {
        const obj = {
        }
        obj.text = `${nv.dest_name}`
        obj.value = nv.dest_id

        return obj
      })
    },

    updateSelectedDestination(state, newValue) {
      state.selectedDestination = newValue
    },
  },
  actions: {
    async loadDestinationList(context, query) {
      const result = await agent.Destination.all(query)
      context.commit('updateDestinationList', result.data)
    },

    async loadDestination(context, id) {
      const result = await agent.Destination.detail(id)
      context.commit('updateSelectedDestination', result.data)
    },

    async createDestination(context, destination) {
      await agent.Destination.add(destination)
    },
    async editDestination(context, destination) {
      await agent.Destination.update(destination)
    },

    async deleteDestination(context, id) {
      await agent.Destination.delete(id)
    },
  },
}

export default destinationStore
