import axios from 'axios'

import fileDownload from 'js-file-download'

axios.defaults.baseURL = '/api/v1/admins'

// eslint-disable-next-line consistent-return
export function init(store, router) {
  axios.interceptors.request.use(
    config => {
      // Do something before request is sent
      const accessToken = localStorage.getItem('accessToken')
      const lang = localStorage.getItem('lang')

      if (lang) {
        if (lang === 'zh') {
          console.log('init language')
          // eslint-disable-next-line no-param-reassign
          config.headers.lang = 'zh-CN'
        } else {
          // eslint-disable-next-line no-param-reassign
          config.headers.lang = lang
        }
      }

      // eslint-disable-next-line no-param-reassign
      if (accessToken) config.headers.Authorization = `${accessToken}`

      return config
    },
    error => Promise.reject(error),
  )

  axios.interceptors.response.use(
    async response => {
      const accessToken = response.headers.authorization

      if (accessToken !== undefined) {
        localStorage.setItem('accessToken', accessToken)
      }

      return response
    },
    error => {
      const { status, config } = error.response

      const accessToken = localStorage.getItem('accessToken')

      switch (status) {
        case 422:
          break
        case 401:
          if (config.headers.Authorization && accessToken !== config.headers.Authorization) {
            config.headers.Authorization = accessToken

            return axios(config)
          }

          router.push({
            name: 'auth-login',
          })
          break
        default:
          break
      }

      return Promise.reject(error)
    },
  )

  // console.log(router)
  // console.log(store.method)
  if (store.method !== undefined) {
    return axios(store)
  }
}

const responseBody = response => response.data

const Auth = {
  login: user => axios.post('/login', user),
  authInfo: () => axios.get('/user-profile'),
  logout: () => axios.post('/logout'),
  uploadPhoto: img => {
    const formData = new FormData()
    formData.append('material_img', img)

    return axios
      .post('/materials/photo', formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(responseBody)
  },
  update: material => axios.put(`/materials/${material.id}`, material),
  delete: id => axios.delete(`/materials/${id}`),
  detail: id => axios.get(`/materials/${id}`).then(responseBody),
}

const User = {
  all: query => axios.get('/users', {
    params: query,
  }).then(responseBody),
  createInfo: () => axios.get('/users/create').then(responseBody),
  add: user => axios.post('/users', user),
  update: user => axios.put(`/users/${user.id}`, user),
  updateInfo: id => axios.get(`/users/${id}/edit`).then(responseBody),
  delete: id => axios.delete(`/users/${id}`),
  detail: id => axios.get(`/users/${id}/edit`).then(responseBody),
}
const Role = {
  all: query => axios
    .get('/roles', {
      params: query,
    })
    .then(responseBody),
  createInfo: () => axios.get('/roles/create').then(responseBody),
  add: role => axios.post('/roles', role),
  update: role => axios.put(`/roles/${role.id}`, role),
  updateInfo: id => axios.get(`/roles/${id}/edit`).then(responseBody),
  delete: id => axios.delete(`/roles/${id}`),
  detail: id => axios.get(`/roles/${id}/edit`).then(responseBody),
}
/* eslint implicit-arrow-linebreak: ["error", "beside"] */
const Material = {
  all: query => axios.get('/materials', {
    params: query,
  }).then(responseBody),
  getids: () => axios.get('/materialIds').then(responseBody),
  add: material => axios.post('/materials', material),
  uploadPhoto: img => {
    const formData = new FormData()
    formData.append('material_img', img)

    return axios
      .post('/materials/photo', formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(responseBody)
  },
  update: material => axios.put(`/materials/${material.id}`, material),
  delete: id => axios.delete(`/materials/${id}`),
  detail: id => axios.get(`/materials/${id}`).then(responseBody),
}

const Pellet = {
  all: query => axios
    .get('/pellets', {
      params: query,
    })
    .then(responseBody),
  add: pellet => axios.post('/pellets', pellet),
  getids: () => axios.get('/pelletIds').then(responseBody),
  update: pellet => axios.put(`/pellets/${pellet.id}`, pellet),
  delete: id => axios.delete(`/pellets/${id}`),
  detail: id => axios.get(`/pellets/${id}`).then(responseBody),
}

const Country = {
  all: query => axios
    .get('/countries', {
      params: query,
    })
    .then(responseBody),
  getids: () => axios.get('/countryIds').then(responseBody),
  add: country => axios.post('/countries', country),
  update: country => axios.put(`/countries/${country.id}`, country),
  delete: id => axios.delete(`/countries/${id}`),
  detail: id => axios.get(`/countries/${id}`).then(responseBody),
}

const Area = {
  all: query => axios
    .get('/areas', {
      params: query,
    })
    .then(responseBody),
  getids: () => axios.get('/areaIds').then(responseBody),
  add: area => axios.post('/areas', area),
  update: area => axios.put(`/areas/${area.id}`, area),
  delete: id => axios.delete(`/areas/${id}`),
  detail: id => axios.get(`/areas/${id}`).then(responseBody),
}

const Customer = {
  // 全顧客情報を取得する
  all: query => axios.get('/customers', {
    params: query,
  }).then(responseBody),

  // 許可後の全(正式)顧客情報を取得する
  allowed: query => axios
    .get('/customersallowed', {
      params: query,
    })
    .then(responseBody),

  getids: () => axios.get('/customerids').then(responseBody),

  add: customer => axios.post('/customers', customer),
  update: customer => axios.put(`/customers/${customer.cust_id}`, customer),
  delete: id => axios.delete(`/customers/${id}`),
  detail: id => axios.get(`/customers/${id}`).then(responseBody),
  statuses: query => axios.get('/customerstatuses', {
    params: query,
  }).then(responseBody),
}

const Dashboard = {
  all: () => axios.get('/dashboard').then(responseBody),
  add: dashboard => axios.post('/dashboard', dashboard),
  uploadPhoto: img => {
    const formData = new FormData()
    formData.append('dashboard_image', img)

    return axios
      .post('/dashboard/photo', formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(responseBody)
  },
  update: dashboard => axios.put(`/dashboard/${dashboard.id}`, dashboard),
  detail: id => axios.get(`/dashboard/${id}`).then(responseBody),
  delete: id => axios.delete(`/dashboard/${id}`),
  list: () => axios.get('/dashboard-list').then(responseBody),
}

const Employee = {
  all: query => axios
    .get('/employees', {
      params: query,
    })
    .then(responseBody),
  add: employee => axios.post('/employees', employee),
  getids: () => axios.get('/employeeIds').then(responseBody),
  update: employee => axios.put(`/employees/${employee.id}`, employee),
  delete: id => axios.delete(`/employees/${id}`),
  detail: id => axios.get(`/employees/${id}`).then(responseBody),
}

const Setting = {
  all: query => axios
    .get('/setting', {
      params: query,
    })
    .then(responseBody),
}

const Report = {
  /**
   * Assign the project to an employee.
   * @param {Object} info - The employee who is responsible for the project.
   * @param {string} info.date - report month i.e.202112
   * @param {string} info.data - post body
   */
  /*eslint-disable*/
  monthlyReportDownload: info =>
    axios.post(`/monthlyreport/${info.date}`, info.data, { responseType: 'arraybuffer' }).then(res => {
      const content = res.headers['content-disposition']
      let filename = 'report.xlsx'
      if (content) {
        let name1 = content.match(/filename=(.*);/)[1] // filename
        let name2 = content.match(/filename\*=(.*)/)[1] // filename*
        name1 = decodeURIComponent(name1.replaceAll('"', ''))
        name2 = decodeURIComponent(name2.substring(7)) //
        filename = name2 || name1
      }
      fileDownload(res.data, filename)
    }),
  crushedStockReportDownload: () =>
    axios.get('/crushedstockreport', { responseType: 'arraybuffer' }).then(res => {
      const content = res.headers['content-disposition']
      let filename = 'report.xlsx'
      if (content) {
        let name1 = content.match(/filename=(.*);/)[1] // filename
        let name2 = content.match(/filename\*=(.*)/)[1] // filename*
        name1 = decodeURIComponent(name1.replaceAll('"', ''))
        name2 = decodeURIComponent(name2.substring(7)) //
        filename = name2 || name1
      }
      fileDownload(res.data, filename)
    }),
  /*eslint-disable*/
}

const Preorder = {
  all: query => axios.get('/preorders', { params: query }).then(responseBody),
  add: preorder => axios.post('/preorders', preorder),
  update: preorder => axios.put(`/preorders/${preorder.po_id}`, preorder),
  detail: id => axios.get(`/preorders/${id}`).then(responseBody),
  delete: id => axios.delete(`/preorders/${id}`),
}

const Auction = {
  all: query => axios.get('/auctions', { params: query }).then(responseBody),
  add: auction => axios.post('/auctions', auction),
  update: auction => axios.put(`/auctions/${auction.auc_id}`, auction),
  detail: id => axios.get(`/auctions/${id}`).then(responseBody),
  delete: id => axios.delete(`/auctions/${id}`),
}

//コンテナー　Container
const Container = {
  all: query => axios.get('/containers', { params: query }).then(responseBody),
  add: container => axios.post('/containers', container),
  update: container => axios.put(`/containers/${container.cntr_id}`, container),
  detail: id => axios.get(`/containers/${id}`).then(responseBody),
  delete: id => axios.delete(`/containers/${id}`),

}

//document
const Document = {
  all: query => axios.get('/documents', { params: query }).then(responseBody),
  update: document => axios.put(`/documents/${document.cont_id}`, document),
  detail: id => axios.get(`/documents/${id}`).then(responseBody),
}

//契約　Contract
const Contract = {
  all: query => axios.get('/contracts', { params: query }).then(responseBody),
  add: contract => axios.post('/contracts', contract),
  update: contract => axios.put(`/contracts/${contract.cont_id}`, contract),
  detail: id => axios.get(`/contracts/${id}`).then(responseBody),
  delete: id => axios.delete(`/contracts/${id}`),
  allItems: query => axios.get('/contractitems', { params: query }).then(responseBody),
  addItem: contract => axios.post('/contractitems', contract),
  updateItem: contract => axios.put(`/contractitems/${contract.ci_id}`, contract),
  detailItem: id => axios.get(`/contractitems/${id}`).then(responseBody),
  deleteItem: id => axios.delete(`/contractitems/${id}`),
}

const Template = {
  all: query => axios.get('/templates', { params: query }).then(responseBody),
  add: template => axios.post('/templates', template),
  update: template => axios.put(`/templates/${template.tmpl_id}`, template),
  detail: id => axios.get(`/templates/${id}`).then(responseBody),
  delete: id => axios.delete(`/templates/${id}`),
}

const Reserved = {
  all: query => axios.get('/reservations', { params: query }).then(responseBody),
  detail: id => axios.get(`/reservations/${id}`).then(responseBody),
  update: reserved => axios.put(`/reservations/${reserved.resv_id}`, reserved),
  statuses: query => axios.get('/reservationstatuses', { params: query }).then(responseBody),
  decide: reserved => axios.post(`/reservationsdecision`, reserved),

  //承認済
  approved: query => axios.get('/approvedreservations', { params: query }).then(responseBody),

  //見積書を作成
  createQuotation: reserved => axios.post('/reservationquotations', reserved),
  //見積を取得
  loadQuotation: id => axios.get(`/reservationquotations/${id}`).then(responseBody),
  //見積書を編集
  editQuotation: reserved => axios.put(`/reservationquotations/${reserved.quot_id}`, reserved),
  deleteQuotation: reserved => axios.post('/reservationquotationsdelete', reserved),
  finalizeQuotation: reserved => axios.post('/reservationquotationsfinalize', reserved),
  //履歴を取得
  getProgress: id => axios.get(`/reservationprogresses/${id}`).then(responseBody),
}

//入金
const Deposit = {
  all: query => axios.get('/deposits', { params: query }).then(responseBody),
  detail: id => axios.get(`/deposits/${id}`).then(responseBody),
  update: depo => axios.put(`/deposits/${depo.depo_id}`, depo),
  add: depo => axios.post('/deposits', depo),
  delete: id => axios.delete(`/deposits/${id}`),
}

//売掛
const AccountReceivable = {
  all: query => axios.get('/accountreceivables', { params: query }).then(responseBody),
  detail: id => axios.get(`/accountreceivables/${id}`).then(responseBody),
  update: ar => axios.put(`/accountreceivables/${ar.ar_id}`, ar),

}

const Bid = {
  all: query => axios.get('/biddings', { params: query }).then(responseBody),
  allwon: query=> axios.get('/biddingswon', { params: query }).then(responseBody),
  add: bid => axios.post('/bidddings', bid),
  update: bid => axios.put(`/biddings/${bid.bid_id}`, bid),
  detail: id => axios.get(`/biddings/${id}`).then(responseBody),
  delete: id => axios.delete(`/biddings/${id}`),
  statuses: query => axios.get('/biddingstatuses', { params: query }).then(responseBody),
  win: bid=> axios.post('/biddingtowin', bid),

  //見積書を作成
  createQuotation: bid => axios.post('/biddingquotations', bid),
  deleteQuotation: bid => axios.post('/biddingquotationsdelete', bid),
  finalizeQuotation: bid => axios.post('/biddingquotationsfinalize', bid),
  //見積を取得
  loadQuotation: id => axios.get(`/biddingquotations/${id}`).then(responseBody),
  //見積書を編集
  editQuotation: bid => axios.put(`/biddingquotations/${bid.quot_id}`, bid),
  getProgress: id => axios.get(`/biddingprogresses/${id}`).then(responseBody),

}

const Quotation = {
  all: query => axios.get('/quotations', { params: query }).then(responseBody),

  detail: id => axios.get(`/quotations/${id}`).then(responseBody),

  // //見積書を編集
  // editQuotation: quotation => axios.put(`/biddingquotations/${quotation.quot_id}`, quotation),

  statuses: query => axios.get('/quotationstatuses', { params: query }).then(responseBody),
  doContract: quot => axios.post('/quotationstocontract', quot),
}

//貸借対照
const Balance = {
  all: query => axios.get('/balancesheets', { params: query }).then(responseBody),
}

const Media = {
  getList: query =>
    axios
      .get('/media/list', {
        params: query,
      })
      .then(responseBody),
  upload: query => {
    const formData = new FormData()
    formData.append('img', query.img)
    formData.append('tgtDirectory', query.tgtDirectory)
    formData.append('tgtFolderId', query.tgtFolderId)

    return axios.post('/mediafiles', formData, {
      headers: {
        'Content-type': 'multipart/form-data',
      },
    })
  },
  delete: query =>
    axios.delete('/mediafiles', {
      params: query,
    }),
  rename: mediafiles => axios.put('/mediafiles', mediafiles),
  createFolder: folder => axios.post('/mediafolders', folder),
}

const Currency = {
  all: query => axios.get('/currencies', { params: query }).then(responseBody),
  add: currency => axios.post('/currencies', currency),
  update: currency => axios.put(`/currencies/${currency.ccy_id}`, currency),
  detail: id => axios.get(`/currencies/${id}`).then(responseBody),
  delete: id => axios.delete(`/currencies/${id}`),
}

const Incoterm = {
  all: query => axios.get('/incoterms', { params: query }).then(responseBody),
  add: incoterm => axios.post('/incoterms', incoterm),
  update: incoterm => axios.put(`/incoterms/${incoterm.ic_id}`, incoterm),
  detail: id => axios.get(`/incoterms/${id}`).then(responseBody),
  delete: id => axios.delete(`/incoterms/${id}`),
}

const Destination = {
  all: query => axios.get('/destinations', { params: query }).then(responseBody),
  add: destination => axios.post('/destinations', destination),
  update: destination => axios.put(`/destinations/${destination.dest_id}`, destination),
  detail: id => axios.get(`/destinations/${id}`).then(responseBody),
  delete: id => axios.delete(`/destinations/${id}`),
}

const agent = {
  Material,

  Pellet,
  Country,
  Area,
  Customer,

  Auth,
  User,
  Role,
  Employee,
  Dashboard,
  Setting,
  Report,
  Reserved,
  Bid,
  Quotation,
  Deposit,
  AccountReceivable,
  Balance,

  Preorder,
  Auction,
  Media,
  Contract,
  Container,
  Document,
  Template,
  Currency,
  Incoterm,
  Destination,
}

export default agent
