import agent from '../api/agent'

const accountReceivableStore = {
  namespaced: true,
  state: {
    accountReceivableList: [],
    selectedAccountReceivable: {
    },

  },

  getters: {
  },
  mutations: {
    updateAccountReceivableList(state, newValue) {
      state.accountReceivableList = newValue
    },

    updateSelectedAccountReceivable(state, newValue) {
      state.selectedAccountReceivable = newValue
    },

    clearaccountReceivableList(state) {
      state.accountReceivableList = []
    },

  },
  actions: {
    async loadAccountReceivables(context, query) {
      const result = await agent.AccountReceivable.all(query)

      context.commit('updateAccountReceivableList', result.data)
    },

    async loadAccountReceivable(context, id) {
      const result = await agent.AccountReceivable.detail(id)
      context.commit('updateSelectedAccountReceivable', result.data)
    },

    // 見積書を編集する
    async editAccountReceivable(context, AccountReceivable) {
      const obj = {
        ...AccountReceivable,
      }
      await agent.AccountReceivable.editAccountReceivable(obj)
    },

  },
}

export default accountReceivableStore
