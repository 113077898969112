/* eslint-disable no-unused-vars */
import {
  mdiFileOutline,
  mdiCogOutline,
  mdiBriefcase,
  mdiAccountGroup,
  mdiCube,
  mdiViewList,
  mdiMessageTextClockOutline,
  mdiFileDocumentEditOutline,
  mdiFileDocumentEdit,
  mdiFileClockOutline,
  mdiClipboardListOutline,
  mdiClipboardArrowUpOutline,
  mdiEarth,
  mdiMapMarkerMultiple,
  mdiDrawingBox,
  mdiClipboardCheckMultipleOutline,
  mdiClipboardFlowOutline,
  mdiAccount,
  mdiCart,
  mdiBadgeAccount,
  mdiCarLiftedPickup,
  mdiFileCog,
  mdiBookCogOutline,
} from '@mdi/js'

// import others from './others'

export default [
  {
    title: 'Dashboard',
    icon: mdiCube,
    children: [
      {
        title: '新着情報',
        to: {
          name: 'dashboard-list-new',
        },
        icon: mdiViewList,
        resource: 'mobileDashboard',
        action: 'create',
      },
    ],
  },

  {
    title: '取引先管理',
    icon: mdiAccountGroup,
    children: [
      {
        title: '取引先',
        to: {
          name: 'customer-list',
        },
        icon: mdiAccountGroup,
        resource: 'customer',
        action: 'read',
      },

    ],
  },

  {
    title: '商品管理',
    icon: mdiMessageTextClockOutline,
    children: [
      {
        title: '予約商品',
        to: {
          name: 'preorder-list',
        },
        icon: mdiFileDocumentEdit,
        resource: 'preorder',
        action: 'read',
      },
      {
        title: '入札商品',
        to: {
          name: 'auction-list',
        },
        icon: mdiFileClockOutline,
        resource: 'auction',
        action: 'read',
      },
    ],
  },
  {
    title: '予約管理',
    icon: mdiMessageTextClockOutline,
    children: [

      {
        title: '予約実績',
        to: {
          name: 'reserved-list',
        },
        icon: mdiFileClockOutline,
        resource: 'reserved',
        action: 'read',
      },
      {
        title: '承認済見積',
        to: {
          name: 'approved-list',
        },
        icon: mdiFileDocumentEditOutline,
        resource: 'reservedApproved',
        action: 'read',
      },

      // {
      //   title: '契約進行',
      //   to: {
      //     name: 'reserved-contract',
      //   },
      //   icon: mdiFileClockOutline,
      //   resource: 'reservedContract',
      //   action: 'read',
      // },
    ],
  },
  {
    title: '入札管理',
    icon: mdiClipboardListOutline,
    children: [
      // {
      //   title: '商品登録',
      //   to: { name: 'tender-list' },
      //   icon: mdiFileDocumentEdit,
      //   resource: 'tender',
      //   action: 'read',
      // },
      {
        title: '入札実績',
        to: {
          name: 'bidding-list',
        },
        icon: mdiViewList,
        resource: 'bidding',
        action: 'read',
      },
      {
        title: '落札済見積',
        to: {
          name: 'biddingwon-list',
        },
        icon: mdiViewList,
        resource: 'biddingwon',
        action: 'read',
      },
    ],
  },

  {
    title: '契約管理',
    icon: mdiFileOutline,
    children: [
      {
        title: '見積済',
        to: {
          name: 'quotation-list',
        },
        icon: mdiViewList,
        resource: 'quotation',
        action: 'read',
      },

      {
        title: '本契約',
        to: {
          name: 'contract-list',
        },
        icon: mdiViewList,
        resource: 'contract',
        action: 'read',
      },
      {
        title: 'コンテナー',
        to: {
          name: 'container-list',
        },
        icon: mdiViewList,
        resource: 'product',
        action: 'read',
      },
      {
        title: '契約書条目',
        to: {
          name: 'document-list',
        },
        icon: mdiViewList,
        resource: 'product',
        action: 'read',
      },
      {
        title: '請求書',
        to: {
          name: 'invoice-list',
        },
        icon: mdiViewList,
        resource: 'invoice',
        action: 'read',
      },

    ],
  },

  {
    title: '売上管理',
    icon: mdiFileOutline,
    children: [
      {
        title: '売掛管理',
        to: {
          name: 'ar-list',
        },
        icon: mdiViewList,
        resource: 'sales',
        action: 'read',
      },
      {
        title: '入金管理',
        to: {
          name: 'deposit-list',
        },
        icon: mdiViewList,
        resource: 'payment',
        action: 'read',
      },
      {
        title: '貸借対照表',
        to: {
          name: 'balancesheet-list',

          params: {
            month: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substring(0, 7)
              .replace('-', ''),
          },
        },
        icon: mdiViewList,
        resource: 'balance',
        action: 'read',
      },

    ],
  },

  {
    title: '各種設定',
    icon: mdiCogOutline,
    children: [
      {
        title: '画像',
        to: {
          name: 'media-list',
        },
        icon: mdiDrawingBox,
        resource: 'product',
        action: 'read',
      },

      // {
      //   title: 'ペレット登録',
      //   to: {
      //     name: 'productmaster-list',
      //   },
      //   icon: mdiDrawingBox,
      //   resource: 'product',
      //   action: 'read',
      // },

      {
        title: 'Pellet',
        to: {
          name: 'pellet-list',
        },
        icon: mdiDrawingBox,
        resource: 'pellet',
        action: 'read',
      },

      // {
      //   title: '社員',
      //   to: { name: 'employee-list' },
      //   icon: mdiAccountGroup,
      //   resource: 'employee',
      //   action: 'read',
      // },
      {
        title: 'ユーザー',
        to: {
          name: 'user-list',
        },
        icon: mdiAccount,
        resource: 'user',
        action: 'read',
      },
      {
        title: 'テンプレート',
        to: {
          name: 'template-list',
        },
        icon: mdiAccount,
        resource: 'template',
        action: 'read',
      },

      // {
      //   title: '権限',
      //   to: { name: 'role-list' },
      //   icon: mdiBadgeAccount,
      //   resource: 'role',
      //   action: 'read',
      // },
      {
        title: '国・地域',
        to: {
          name: 'country-list',
        },
        icon: mdiEarth,
        resource: 'country',
        action: 'read',
      },
      {
        title: '契約科目',
        to: {
          name: 'contractItem-list',
        },
        icon: mdiFileCog,
        resource: 'contractItem',
        action: 'read',
      },

      // {
      //   title: '都市',
      //   to: { name: 'area-list' },
      //   icon: mdiMapMarkerMultiple,
      //   resource: 'area',
      //   action: 'read',
      // },

      {
        title: '各種マスター',
        to: {
          name: 'master-list',
        },
        icon: mdiBookCogOutline,
        resource: 'role',
        action: 'read',
      },

    ],
  },
]

// Array of sections
// export default [...others]
