import agent from '../api/agent'

const documentStore = {
  namespaced: true,
  state: {
    documentList: [],
    selectedDocument: {
    },

  },

  getters: {
  },

  mutations: {

    updateDocuments(state, newValue) {
      state.documentList = newValue
    },

    updateDocument(state, newValue) {
      state.selectedDocument = newValue
    },

  },

  actions: {

    async loadDocuments(context, query) {
      const result = await agent.Document.all(query)
      context.commit('updateDocuments', result.data)
    },

    async loadDocument(context, id) {
      const result = await agent.Document.detail(id)
      context.commit('updateDocument', result.data)
    },

    async editDocument(context, document) {
      await agent.Document.update(document)
    },

  },
}

export default documentStore
